.da-footer-container {
  margin: 3vh 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.da-footer-link {
  letter-spacing: normal;
  color: var(--DAsecondaryColour);
  text-decoration: none;
}

.da-footer-link:hover {
  color: var(--DAsecondaryColour);
  border-bottom: 1px solid var(--DAsecondaryColour);
}

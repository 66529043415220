/* home page */

.home-page-container  {
    display: flex;
    flex-direction: column;
    background-color: #022172;
    height: 100vh;
    width: 100%;
    padding-bottom: 5vh;
    position: relative; 
    font-family: 'Source Code Pro';
    padding-top: 1%;
  }
  
  .home-page-animation {
    height: 100%;
    background-image: url('/src/images/Flag_of_Scotland.gif');
    background-size: cover;
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10; 
    animation: fadeout 2s 1 linear forwards;
  }
  
  @keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; visibility: hidden; } 
  }
  
  .home-page-content {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5; 
    opacity: 1;
    transition: opacity 3s ease-in-out;
  }
  
  .website-title-c1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
  }
  
  .hp-links-c2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 90%;
    margin-top: 4%;
  }
  
  .hp-button-image, .hp-button-image-hibs {
    display: block;
    height: 150px;
    width: 30vh;
    border: 2px solid rgb(255, 0, 0);
    border-radius: 3px;
    border-style: groove;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
    position: relative;
    margin: 5px;
    border-radius: 6px;
    box-shadow: 5px 2px 2px rgb(10,83,200);
    -webkit-mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
    mask-image: linear-gradient(45deg,#000 25%,rgba(0,0,0,.2) 50%,#000 75%);
    -webkit-mask-size: 800%;
    mask-size: 800%;
    -webkit-mask-position: 0;
    mask-position: 0;
  }
  
  .hp-button-image:hover {
    transition: mask-position 2s ease,-webkit-mask-position 2s ease;
    -webkit-mask-position: 120%;
    mask-position: 120%;
    opacity: 1;
  }

  .hp-button-div-hibs{
    height: 150px;
    width: 30vh;
    border: 2px solid rgb(255, 0, 0);
    border-radius: 3px;
    border-style: groove;
    background-image: url("./hibs_manager_merger/public/fenlon.jpg");
    background-size: cover;
    background-position: center;
    color: transparent;
    transition: background-image 1.5s ease-in-out;
    }

  .hp-button-div-hibs:hover{
    background-image: url("./hibs_manager_merger/public/gray.png");
    }

  
  .hp-button-text1, .hp-button-text2 {
    color:rgb(243, 242, 223);
    padding: 10px 20px;
    font-size: 20px;
    font-weight: bold;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    text-shadow:2px 2px 2px #011346;
    position: absolute;
  }

  .hp-button-text1 {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hp-button-text2 {
    text-align: left;
    top: 30%;
    left: 26%;
    transform: translate(-50%, -50%);
  }
  
  .home-page-link {
    text-align: center;
  }
  
  .hp-button-div {
    display: flex;
    justify-content: center;
    max-width: 20%;
    position: relative;
    text-align: center;
  }

  .hp-row1, .hp-row2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  @media (orientation: landscape){
    .hp-links-c2 {
      flex-direction: row;
    }
    .footer-container {
      flex-direction: row;
    }
  }
  
  @media (orientation: portrait) {
    .home-page-content {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media screen and (max-width: 1020px),
        screen and (max-height: 600px) {
          .hp-links-c2 {
            width: 100%;
            justify-content: space-around;
          }
          .hp-button-text2 {
            left: 15%;
          }
          .hp-button-image, .hp-button-div-hibs, .hp-button-image-hibs {
            max-width: 20vh;
            height: 150px;
          }
        }

  @media screen and (max-width: 1180px),
        screen and (max-height: 820px) {
          .hp-links-c2 {
            width: 100%;
            justify-content: space-around;
          }
          .hp-button-image, .hp-button-div-hibs, .hp-button-image-hibs {
            max-width: 25vh;
            height: 150px;
          }
          .hp-button-text1, .hp-button-text2 {
          font-size: 13px;
          }
        }

  @media screen and (max-width: 1024px)
   {
    /* .home-page-content {
      flex-direction: column;
      align-items: center;
    } */
    .home-page-animation{
      background-size: 100%;
    }
    .hp-button-image, .hp-button-div-hibs, .hp-button-image-hibs {
      max-width: 30vh;
      height: 150px;
    }
    .hp-button-text1, .hp-button-text2 {
      font-size: 15px;
    }
    .hp-button-text2 {
      top: 30%;
      left: 26%;
    }
    .footer-container {
      width: 100%;
    }
  }

 @media screen and (max-width: 768px) {
  .home-page-content {
    flex-direction: column;
    align-items: center;
  }
  .hp-links-c2 {
    flex-direction: column;
    justify-content: space-between;
  }
  .hp-button-image, .hp-button-div-hibs {
    width: 22vh;
    height: 18vh;
  }

  .hp-button-text1, .hp-button-text2 {
    font-size: 10px;
  }
  .hp-button-text2 {
    left: 30%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 385px) {
  .hp-button-image, .hp-button-div-hibs, .hp-button-image-hibs {
    width: 17vh;
    height: 15vh;
  }
}

@media screen and (max-height: 344px) {
  .hp-button-image, .hp-button-div-hibs, .hp-button-image-hibs {
    max-width: 25vh;
    height: 120px;
  }
  .hp-links-c2 {
    flex-direction: row;
    justify-content: center;
  }
}

.twenty48 {
  margin: calc(var(--pixel-size) * 0) auto;
  padding: calc(var(--pixel-size) * 1);
  width: 100%;
  height: 100%;
  background-color: var(--primary-background);
  display: flex;
  flex-direction: column;
}

.twenty48 > .hibs-header {
  display: flex;
  align-items: center;
  margin: calc(var(--pixel-size) * 1) calc(var(--pixel-size) * 0);
}

.twenty48 > .hibs-header > * {
  flex: 1;
}

.twenty48 > footer {
  /* margin: calc(var(--pixel-size) * 3) calc(var(--pixel-size) * 0); */
  font-weight: 600;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: -100px;
  margin-top: -100px;
  width: 100%;
  color: var(--primary-text-color);
}

@media (min-width: 512px) {
  .twenty48 > footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .twenty48 > footer > div {
    width: 100%;
  }
}

@media (max-height: 710px) {
  .twenty48 > footer {
    font-size: 12px;
    font-weight: 400;
  }
}
@media (max-width: 610px) {
  .twenty48 > footer {
    font-size: 10px;
    font-weight: 200;
  }
}
.startScreen-container, .mainComponent-container,
.creditsScreen-container, .aboutScreen-container{
  font-family: "Rubik Mono One", monospace;
}

/* 
.App-link {
  color: #61dafb;
} */



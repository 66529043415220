.modal-wrapper {
    position: fixed;
    left: 0;
    top: 50%;
    width: 100%;
    height: 100vh;
    z-index: 10;
  }

  .modal {
    position: relative;
    width: 50%;
    min-height: 200px;
    background-color: rgba(255, 255, 255, 0.95);
    border: 3px solid #999;
    border-radius: 3px;
    margin: 20px auto;
    padding: 20px;
    color: black;
    text-align: center;
    line-height: 2;
  }

  .warning {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #id-input {
    max-width: 100px;
    width: auto;
  }

  .modalExit-btn {
    font-size: 1.5rem;
    color: var(--primaryColor);
    background-color: var(--secondaryBackground);
    border: 1px solid var(--primaryColor);
    text-decoration: none;
    padding: 0.5rem 2.5rem;
    margin: 0.5rem;
    border: 1px solid black;
  }
  .modalExit-btn:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 512px) {
    .modalExit-btn {
      font-size: 1rem;
  }
}
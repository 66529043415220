.gers-gameScreen-container {
   border: solid 2px #fff;
 
}


  button {
    position: relative;
    z-index: 1; 
  }


  
 

  
.creditsScreen-container {
  font-family: "Rubik Mono One", monospace;
  color: var(--DAprimaryColour);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  animation: creditsScreen-load 2s ease-in 0ms;
  animation-fill-mode: forwards;
  transform: translateY(-10px);
  opacity: 0;
}

.creditsScreen-header {
  font-size: 1.75rem;
}

.creditsScreen-block {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 1vw;
}

.creditsScreen-text {
  margin: 0.5vw;
}


.creditsScreen-screenLink {
  color: var(--DAprimaryColour);
  background-size: 50.1% var(--p, 0.08em);
  background-color: var(--secondaryBackground);
  font-size: 1.25rem;
  margin: 2vh 0 3vh;
  padding: 1vh;
  border: 1px solid var(--DAsecondaryColour);
  text-decoration: none;
  transition: color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  animation: btn-load 1s ease-in;
}

.creditsScreen-screenLink:hover {
  color: var(--DAbackgroundColour);
  box-shadow: inset 200px 0 0 0 var(--DAsecondaryColour);
}

.creditsScreen-personalLink {
  color: var(--DAsecondaryColour);
  margin-bottom: 2vh;
  text-decoration: none;
  transition: 0.25s;
}

.creditsScreen-personalLink:hover {
  color: var(--DAsecondaryColour);
  scale: 1.1;
  transition: 0.25s;
}

.imageCreditsList, .audioCreditsList {
  line-height: 3;
  margin-bottom: 15px;
  border-style: outset;
  list-style-type: none;
  padding: 1%;
  }


.creditsScreen-content > h2 {
  text-align: center;
}

@keyframes creditsScreen-load {
  0% {
    transform: translateY(-700px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.gers-creditsScreen-container {
  font-family: "Poppins", monospace;
  color: var(--gersPrimaryColor);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  animation: creditsScreen-load 2s ease-in 0ms;
  animation-fill-mode: forwards;
  transform: translateY(-10px);
  opacity: 0;
  background: rgb(2,0,36);
background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,51,160,1) 28%, rgba(0,118,200,1) 60%, rgba(0,212,255,1) 100%);
}

.gers-creditsScreen-header {
  font-size: 1.75rem;
}

.gers-creditsScreen-block {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 1vw;
}

.gers-creditsScreen-text {
  margin: 0.5vw;
}

.gers-creditsScreen-text > ul > a:link {
  color: var(--gersSecondaryColor);
}
.gers-creditsScreen-text > ul > a:visited {
  color: rgb(152, 2, 2);
  text-shadow: 1px white;
}


.gers-creditsScreen-screenLink {
  font-size: 1.5rem;
  color: var(--gersPrimaryColor);
  background-color: var(--secondaryBackground);
  border: 1px solid var(--gersPrimaryColor);
  text-decoration: none;
  padding: 0.5rem 2.5rem;
  margin: 0.5rem;
  transition: color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  animation: btn-load 1s ease-in;
}

.gers-creditsScreen-screenLink:hover {
  color: var(--gersPrimaryColor);
  box-shadow: inset 200px 0 0 0 var(--gersSecondaryColor);
}

.gers-creditsScreen-personalLink {
  color: var(--gersSecondaryColor);
  margin-bottom: 2vh;
  text-decoration: none;
  transition: 0.25s;
}

.gers-creditsScreen-personalLink:hover {
  color: var(--gersSecondaryColor);
  scale: 1.1;
  transition: 0.25s;
}

.gers-imageCreditsList {
  line-height: 3;
  list-style: none;
  text-align: left;
  padding: 10px;
  margin-bottom: 15px;
  border-style: outset;
  background-color: rgba(8, 3, 117, 0.5);
  }


.gers-creditsScreen-content > h2 {
  text-align: center;
}

@keyframes creditsScreen-load {
  0% {
    transform: translateY(-700px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.aboutScreen-container {
  font-family: "Rubik Mono One", monospace;
  letter-spacing: 0.1rem;
  color: var(--DAprimaryColour);
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  margin-top: 5vh;
  padding-bottom: 5vh;
  animation: aboutScreen-load 400ms ease-in 200ms;
  animation-fill-mode: forwards;
  transform: translateY(-50px);
  opacity: 0;
}

.aboutScreen-linksContainer {
  display: flex;
  flex-flow: column nowrap;
  margin: 5vh auto;
  padding: 2vh 0;
  border-top: 1px solid var(--secondaryColor);
  border-bottom: 1px solid var(--secondaryColor);
}

.aboutScreen-link {
  color: var(--DAprimaryColour);
  background-size: 50.1% var(--p, 0.08em);
  background-color: var(--secondaryBackground);
  font-size: 1.25rem;
  margin: 2vh 0 3vh;
  padding: 1vh;
  border: 1px solid var(--DAsecondaryColour);
  text-decoration: none;
  transition: color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  animation: btn-load 1s ease-in;
}

.aboutScreen-link:hover {
  color: var(--DAbackgroundColour);
  box-shadow: inset 200px 0 0 0 var(--DAsecondaryColour);
}

@keyframes aboutScreen-load {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.about-footer-div {
    display: flex;
    justify-content: center;
    width: 100%;
}
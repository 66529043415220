.App {
  text-align: center;
  height: 100vh;
}

/* footer */

.footer-div {
  display: flex;
  justify-content: center;
}

.footer-container {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2%;
  margin: 1%;
  width: 50%;
  height: 5vh;
  position: fixed;
  bottom: 0;
}

.footer-link {
  letter-spacing: normal;
  color: rgb(244, 219, 198);
  font-size: large;
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: rgb(128, 0, 111);
  text-decoration: none;
  transition: 0.5s;
  margin: 0 2px;
  z-index: 1;
}

.footer-link:hover {
  color: red;
  border-bottom: 1px solid var(--DAsecondaryColour);
  transition: 0.5s;
}

@media (orientation: portrait),
screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    height: 15vh;
    padding: 1%;
  }
  .footer-link {
    font-size: 15px;
  }
}
:root {
  --pixel-size: 8px;

  /* Backgrounds */
  --primary-background: #00391B;
  --secondary-background: #007A3E;
  --cell-background: #3A2C5C;
  --tile-background: #F84E7C;
  --button-background: #461B71;

  /* Colors */
  --primary-text-color: #fff;
  --secondary-text-color: #f1b5b5;
}

/* .twenty48 {
  margin: 0;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  background: var(--primary-background);
  color: var(--primary-text-color);
  font-size: calc(var(--pixel-size) * 2.25);
  line-height: calc(var(--pixel-size) * 3.5);
  
} */

.hibs-merge-main {
  margin: 5px;
  display: flex;
  justify-content: center;
  z-index: 2;
  position: relative;
}

.instructions {
  color: var(--primary-text-color);
  background-color: rgb(58, 44, 92, 0.7);
  padding: 5px;
  z-index: 3;
  position: fixed;
  width: 100%;
  line-height: 2;
}
.credit {
  color: var(--secondary-text-color)
}

.footer-links {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-height: 710px) {
  .footer-links {
    font-size: 16px;
  }
}
.descriptionScreen-container {
  font-family: "Rubik Mono One", monospace;
  color: var(--DAprimaryColour);
  animation: descriptionScreen-load 400ms ease-in 200ms;
  animation-fill-mode: forwards;
  transform: translateY(-50px);
  opacity: 0;
}

.descriptionScreen-header {
  border-top: 5px solid var(--DAsecondaryColour);
  margin: 0;
  padding: 2vh;
  text-align: center;
}

.descriptionScreen-text {
  
  font-size: 1.4rem;
  padding: 2vh 2vw;
  margin: 0 1vw;
  border-top: 5px solid var(--DAsecondaryColour);
  border-bottom: 5px solid var(--DAsecondaryColour);
}

.descriptionScreen-image {
  display: block;
  border: 5px solid var(--DAsecondaryColour);
  width: 512px;
  max-width: 90vw;
  object-fit: cover;
  margin: 0 auto 2vh;
}

.descriptionScreen-link {
  color: var(--DAsecondaryColour);
  text-decoration: none;
}

.descriptionScreen-link:hover {
  color: var(--DAsecondaryColour);
  text-decoration: underline;
}

.descriptionScreen-subHeader {
    font-size: 1.4rem;
}
.techStack-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.descriptionScreen-socialMediaLink {
  border-top: 1px solid var(--DAsecondaryColour);
  padding-top: 1rem;
}

@keyframes descriptionScreen-load {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

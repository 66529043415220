.score {
  background: var(--secondary-background);
  border: calc(var(--pixel-size) * 0.5) solid var(--secondary-background);
  border-radius: calc(var(--pixel-size) * 0.75);
  color: var(--primary-text-background);
  font-size: calc(var(--pixel-size) * 1.5);
  line-height: calc(var(--pixel-size) * 3);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.score > div {
  font-size: calc(var(--pixel-size) * 2.5);
  color: var(--primary-text-color);
}

@media (min-width: 512px) {
  .score {
    border: calc(var(--pixel-size) * 1) solid var(--secondary-background);
    font-size: calc(var(--pixel-size) * 2);
    line-height: calc(var(--pixel-size) * 3.5);
  }

  .score > div {
    font-size: calc(var(--pixel-size) * 3.5);
  }
}

body {
  text-align: center;
  font-size: 1em;
  font-family: "Bebas Neue", sans-serif;
  margin: 0;
}

.stramash{
  /* overflow-y: auto; */
  height: 100vh;
}

.stram-transparent-div {
  background-color: rgba(255, 255, 255, 0.753);
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.stram-image-div {  
  background-image: url('/src/stramash/images/New_Douglas_Park_-_1.jpg');
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  z-index: 1;
  top: 0;
  left: 0;
  position: absolute;
  
}

/* title */
.stram-title {
  line-height: 0.5;
  border-bottom: 1px solid #eee;
  margin: 5px;
}

.stram-title-header {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 100px;
  margin: 2%;
}

.stram-subheader {
  margin: 1%;
}

/* helpers-row */
.stram-helpers-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 5px;
}

.stram-helper-div {
  float: left;
  width: 33.33%;
  border-right: 1px solid #eee;
  padding: 10px;
}

.stram-instructions {
  text-align: left;
  line-height: 2;
}

.stram-instructions-list {
  
  padding-left: 0;
  margin: 0; 
}

/* buttons */
.stram-instructions-button, .stram-clue-button, .stram-category-button {
  background-color: white;
  padding: 1%;
}

.stram-instructions-button {
  box-shadow: 3px 6px green;
}


/* rows */
.stram-row {
  text-align: center;
  display: flex;
  justify-content: center;
}
.stram-row > .space-div {
  width: min(2vw,20px);
  border: 0px solid #4b3939;
}
.stram-row > div {
  display: block;
  width: 60px;
  height: 60px;
  border: 1px solid #4b3939;
  margin: 4px;
  text-align: center;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5em;
}
.stram-row > div.green {
  --background: #5ac85a;
  --border-color: #5ac85a;
  animation: flip 0.5s ease forwards;
}
.stram-row > div.grey {
  --background: #a1a1a1;
  --border-color: #a1a1a1;
  animation: flip 0.6s ease forwards;
}
.stram-row > div.yellow {
  --background: #e2cc68;
  --border-color: #e2cc68;
  animation: flip 0.5s ease forwards;
}
.stram-row > div:nth-child(2) {
  animation-delay: 0.1s;
}
.stram-row > div:nth-child(3) {
  animation-delay: 0.2s;
}
.stram-row > div:nth-child(4) {
  animation-delay: 0.3s;
}
.stram-row > div:nth-child(5) {
  animation-delay: 0.4s;
}
.stram-row > div:nth-child(6){
  animation-delay: 0.5s;
}
.stram-row > div:nth-child(7){
  animation-delay: 0.6s;
}
.stram-row > div:nth-child(8){
  animation-delay: 0.7s;
}
.stram-row > div:nth-child(9){
  animation-delay: 0.8s;
}
.stram-row > div:nth-child(10){
  animation-delay: 0.9s;
}
.stram-row > div:nth-child(11){
  animation-delay: 1s;
}
.stram-row > div:nth-child(12){
  animation-delay: 1.1s;
}
.stram-row > div:nth-child(13){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(14){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(15){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(16){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(17){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(18){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(19){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(20){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(21){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(22){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(24){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(25){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(26){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(27){
  animation-delay: 1.2s;
}
.stram-row > div:nth-child(28){
  animation-delay: 1.2s;
}
.stram-row.current > div.filled {
  animation: bounce 0.2s ease-in-out forwards;
}
.stram-keypad {
  max-width: 1400px;
  margin: 10px auto;
  border-top: 1px black solid;
}
.stram-keypad > div {
  margin: 5px;
  width: 40px;
  height: 50px;
  background: #eee;
  display: inline-block;
  border-radius: 6px;
  line-height: 50px;
}
.stram-keypad > div.green {
  background: #5ac85a;
  color: #fff;
  transition: all 0.3s ease-in;
}
.stram-keypad > div.yellow {
  background: #e2cc68;
  color: #fff;
  transition: all 0.3s ease-in;
}
.stram-keypad > div.grey {
  background: #a1a1a1;
  color: #fff;
  transition: all 0.3s ease-in;
}

.stram-modal {
  background: rgba(255,255,255,0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  z-index: 3;
}

.stram-modal-return-button {
  display: flex;
  justify-content: center;
  width: 100%;
}
.stram-modal div {
  max-width: 480px;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  margin: 10% auto;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}
.stram-modal .solution {
  color: #ff004c;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* keyframe animations */
@keyframes flip {
  0% {
    transform: rotateX(0);
    background: #fff;
    border-color: #333;
  }
  45% {
    transform: rotateX(90deg);
    background: white;
    border-color: #333;
  }
  55% {
    transform: rotateX(90deg);
    background: var(--background);
    border-color: var(--border-color);
  }
  100% {
    transform: rotateX(0deg);
    background: var(--background);
    border-color: var(--border-color);
    color: #eee;
  }
}

@keyframes bounce {
  0% { 
    transform: scale(1);
    border-color: #ddd;
  }
  50% { 
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    border-color: #333;
  }
}

/* responsive */

@media only screen and (max-width: 600px) {
  .stram-title-header {
    font-size: 60px;
    margin: 5%;
  }
  .stram-row {
    padding: 0px 5px;
  }
  .stram-row > div {
    width: 35px;
    height: 35px;
    margin: 3px;
    text-align: center;
    line-height: 40px;
    font-size: 1.5em;
  }

  .stram-keypad > div {
    margin: 5px;
    width: 30px;
    height: 40px;
    border-radius: 3px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 700px),
screen and (max-height: 300px) {
  .stram-title-header {
    font-size: 60px;
    margin: 5%;
  }
  .stram-row {
    padding: 0px 5px;
  }
  .stram-row > div {
    width: 35px;
    height: 35px;
    margin: 3px;
    text-align: center;
    line-height: 40px;
    font-size: 1.5em;
  }

  .stram-keypad > div {
    margin: 5px;
    width: 30px;
    height: 40px;
    border-radius: 3px;
    line-height: 40px;
  }
}

